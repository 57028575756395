@media (min-width: 1023px) and (max-width: 1440px) {
  .intro {
    margin: 40px 8%;

    padding: 0;
  }
  .intro_wallet-text {
    width: 50%;
  }
  .intro_wallet-img img {
    height: 450px;
  }
  .intro_wallet-text h2 {
    font-size: 56px;
  }
}

/* Tablet */

@media (min-width: 769px) and (max-width: 1023px) {
  .background {
    background-size: contain;
  }

  p {
    font-size: 14px;
    line-height: 20px;
  }

  .content {
    display: block;
  }

  .background {
    top: 50px;
    height: 2400px;
  }

  .content {
    height: 100%;
    /* min-height: 3300px; */
  }

  .header {
    padding: 18px 40px;
  }

  .header_img {
    height: 30px;
  }

  .header_img img {
    height: 30px;
  }

  .header_btn :last-child {
    border: 1px solid var(--main-color);
    padding: 8px 40px;
  }

  .intro {
    margin: 40px 0;
    margin-left: 10%;
    padding: 0;
  }

  .intro_wallet {
    box-sizing: border-box;
    justify-content: space-between;
  }

  .intro_wallet-text {
    margin-right: 0;
    /* padding-left: 5rem; */
    width: 45%;
  }

  .intro_wallet-text h2 {
    width: 260px;
    font-size: 34px;
    line-height: 56px;
  }

  .intro_wallet-text p {
    margin: 20px 0;
    font-size: 14px;
  }

  .intro_wallet-text button {
    height: 40px;
    width: 180px;
    font-size: 14px;
    line-height: 20px;
  }

  .intro_wallet-img {
    height: 100%;
  }

  .intro_wallet-img img {
    width: 340px;
    padding-right: 40px;
    padding-left: 8vw;
  }

  /*  */

  .intro_buy {
    margin-top: 60px;
  }

  .intro_buy img {
    margin-left: 3vw;
    padding-bottom: 170px;
  }

  .intro_buy div {
    padding-left: 0rem;
    text-align: center;
  }

  .intro_buy h4,
  .intro_explore h4 {
    height: 50px;
    margin: 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
  }

  .intro_buy p,
  .intro_explore p {
    width: 280px;
    height: 72px;
    margin-top: 19px;
    text-align: center;
  }

  /*  */

  .intro_explore {
    padding-left: 5vw;
    padding-right: 0rem;
    margin-top: 103px;
    align-items: flex-start;
  }

  .intro_explore img {
    width: 300px;
    padding-top: 160px;
    margin-right: 10%;
  }

  .get {
    height: 250px;
  }

  .get h1 {
    font-size: 44px;
    padding-top: 60px;
  }

  .about {
    padding-left: 3vw;
    padding-right: 3vw;
    padding-bottom: 40px;
  }

  .about img {
    width: 400px;
  }

  .about_text {
    padding-left: 20px;
    padding-top: 10px;
  }

  .about_text h3 {
    font-size: 40px;
    padding-bottom: 40px;
  }

  .about_text img {
    margin-right: 20px;
    width: 50px;
    height: 50px;
  }

  .about_text-astrone p,
  .about_text-choose p {
    font-size: 12px;
    max-width: 358px;
  }

  .doc {
    margin-top: 40px;
  }

  .partner h2 {
    padding-top: 100px;
    padding-bottom: 60px;
  }

  .partner {
    margin-bottom: 60px;
  }

  .partner div {
    padding: 0 50px 0 50px;
    justify-content: space-around;
  }

  .partner :last-child img {
    height: 36px;
  }

  .footer h3,
  .partner h3 {
    font-size: 40px;
    line-height: 28px;
    text-align: center;
  }

  .footer img {
    margin-top: 34px;
    margin-bottom: 30px;
    width: 180px;
    height: 44px;
  }
}

/* Mobile */

@media (max-width: 768px) {
  p {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
  }
  .header {
    position: fixed;
    background-color: #fff;
    width: 100%;
    z-index: 9999;
    /* padding: 0; */
  }

  .header_menu {
    display: flex;
    width: 40px;
    height: 40px;
    align-items: center;
    font-size: 24px;
    cursor: pointer;
    border-radius: 10px;
    color: rgb(51, 51, 51);
    background: rgb(219, 219, 219);
    padding: 10px 0;
    justify-content: space-evenly;
    align-items: center;
  }

  .header_menu div {
    width: 20px;
    height: 2px;
    background-color: #000;
  }

  .header_item {
    display: none;
    position: fixed;
    top: 76px;
    width: 100%;
    background-color: #fff;
    padding: 39px 31px 31px 31px;
    z-index: 999999999999;
  }

  .header_item button {
    line-height: 24px;
    padding: 11px 32px;
    font-size: 16px;
    background-color: #5833eb;
    color: #fff;
    border: none;
  }

  .header_item-list a {
    width: 164px;
    margin-left: 12px;
    margin-bottom: 37px;
    padding: 9px 14px;
    color: rgb(34, 34, 34);
    cursor: pointer;
    font-size: 16px;
    line-height: 22px;
    height: 38px;
    text-decoration: none;
  }

  .header_item-list a:hover {
    color: #5833eb;
  }

  .header_item-list a img {
    margin-top: 1px;
    margin-left: 5px;
  }

  .header_img {
    height: 40px;
  }

  .header_img img {
    height: 40px;
  }

  .header_btn {
    display: none;
  }

  .header_btn :first-child {
    display: none;
  }

  .header_btn :last-child {
    padding: 5px 30px;
    background-color: #fff;
  }

  .intro {
    padding: 0px 0vw;
    padding-top: 76px;
  }

  .intro_wallet {
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
  }

  .intro_wallet-text {
    text-align: center;
    width: 100%;
    align-items: center;
  }

  .intro_wallet-text h2 {
    font-size: 36px;
    line-height: 40px;
    letter-spacing: -2px;
    color: #353945;
    margin: 0;
    padding-top: 20px;
  }

  .intro_buy h4,
  .intro_explore h4 {
    height: 60px;
    text-align: center;
  }

  .get {
    height: 250px;
  }

  .get h1 {
    padding-top: 60px;
    font-size: 44px;
  }
  .intro_wallet-text {
    margin-right: 0;
  }

  .intro_wallet-text p {
    margin: 20px 0;
  }

  .intro_wallet-text button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--main-color);
    border: none;
    color: #fff;
    height: 50px;
    width: 100%;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
  }

  .intro_wallet-text-btn {
    padding-left: 10px;
  }

  .intro_wallet-img {
    height: 100%;
  }

  .intro_wallet-img img {
    width: 60vw;
  }

  #intro_content {
    flex-direction: column;
  }

  .intro_buy {
    justify-content: center;
    align-items: center;
    padding: 20px;
  }

  .intro_buy img {
    margin: 40px 0 70px 0;
    padding: 0;
    width: 85%;
  }

  .intro_buy div {
    margin: 0;
    padding: 0;
    text-align: center;
  }

  .intro_buy p,
  .intro_explore p {
    padding: 0 10px;
    text-align: center;
  }

  .intro_explore {
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    margin: 0;
    margin-top: 60px;
  }

  .intro_explore img {
    margin: 40px 0 70px 0;
    padding: 0;
    width: 85%;
  }

  .get h1 {
    font-size: 30px;
  }

  .about {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
  }

  .about_img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .about img {
    max-width: 100%;
  }
  .about_img img {
    width: 100%;
  }
  .about_img-check {
    display: flex;
    justify-content: space-between;
    width: 30px;
  }
  .about_img-check1,
  .about_img-check2 {
    width: 8px;
    height: 8px;
    background-color: green;
    border-radius: 8px;
  }

  .about_text {
    padding: 0;
    margin: 0;
  }

  .about_text h3 {
    padding-top: 50px;
    text-align: center;
    font-size: 30px;
  }
  .doc {
    margin-top: 50px;
  }
  .doc h3 {
    font-size: 24px;
  }

  .get h1 {
    font-size: 30px;
  }

  .partner {
    margin-bottom: 24px;
  }

  .partner h2 {
    font-size: 30px;
    padding: 40px;
  }

  .partner div {
    gap: 0rem 20px;
    padding: 0 10px;
    flex-wrap: wrap;
    justify-content: space-around;
    /* height: 3rem; */
  }

  .partner div img {
    height: 30px;
    padding: 0 10px;
    margin-bottom: 16px;
  }
  #lastImg {
    padding-right: 30px;
  }
  .footer {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 32px;
    height: max-content;
  }

  .footer p {
    font-weight: 400;
  }

  .footer :first-child :nth-child(2) {
    padding: 0 20px 0 20px;
  }

  #copyright {
    margin-top: 16px;
  }
  .background {
    background-size: contain;
  }
}
